#registration_div {
  width: 100%;
  float: left;
  display: flex;
}

#brand_logo_div {
  display: flex;  
}
@media (max-width: 1250px) {
}
@media (max-width: 1238px) {
}

@media (max-width: 1199px) {
}
@media (max-width: 1120px) {
}
@media (max-width: 1084px) {
}
@media (max-width: 1066px) {
}
@media (max-width: 1046px) {
}
@media (max-width: 991px) {
}
@media (max-width: 958px) {
}
@media (max-width: 868px) {
}
@media (max-width: 768px) {
  #registration_div {
    display: none;
  }
  #brand_logo_div {
    width: 100%;
    display: flex;
    height: 50%;
  }
}
@media (max-width: 612px) {
  #registration_div {
    display: none;
  }
  #brand_logo_div {
    width: 100%;
    height: 50%;
  }
}
@media (max-width: 577px) {
  #registration_div {
    display: none;
  }
  #brand_logo_div {
    width: 100%;
    height: 50%;
  }
}
@media (max-width: 531px) {
  #registration_div {
    display: none;
  }
  #brand_logo_div {
    width: 100%;
    height: 50%;
  }
}
@media (max-width: 462px) {
  #registration_div {
    display: none;
  }
  #brand_logo_div {
    width: 100%;
    height: 50%;
  }
}
@media (max-width: 360px) {
  #registration_div {
    display: none;
  }
  #brand_logo_div {
    width: 100%;
    height: 50%;
  }
}
