.kyc-custom-checkbox {
   list-style-type:none;
   padding:0;
 }

 .kyc-custom-checkbox li {
   float:left;
  height: 50px;
  position:relative;
  margin-left: 10px;
}

.kyc-custom-checkbox label, .kyc-custom-checkbox input {
  display:block;
  position:relative;
  top:0;
  left:0;
  right:0;
  bottom:0;
}

.kyc-custom-checkbox + input[type="checkbox"] {
  opacity:0.011;
  z-index:100;
  cursor: pointer;
}

.kyc-custom-checkbox input[type="checkbox"]:checked + label {
  background:#6576ff;
  color: #fff;
}

.kyc-custom-checkbox label {
   padding:15px;
   color: #6576ff;
   width: 100%;
   border:1px solid #CCC; 
   background-color: #eef0ff;
   border-radius: 5px;
   text-align: start;
   display: flex;
 justify-content: center;
 align-items: center;
}